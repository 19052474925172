// // Data.js

const Data = [
  {
    marca: "Sarandi",
    produto: "Águas e Refrigerantes",
    categoria: "Bebidas",
    image: "/produtos/bebidas-generico.jpg",
  },
  {
    marca: "Cervejaria Al Capone",
    produto: "Cerveja",
    categoria: "Bebidas",
    image: "/produtos/bebidas-generico.jpg",
  },
  {
    marca: "Bebidas Fruki",
    produto: "Águas, Cervejas e Refrigerantes",
    categoria: "Bebidas",
    image: "/produtos/bebidas-generico.jpg",
  },
  {
    marca: "Beer Bev",
    produto: "Cervejas Patrícia, Zillertal",
    categoria: "Bebidas",
    image: "/produtos/bebidas-generico.jpg",
  },
  {
    marca: "Vinícola Casa Valduga",
    produto: "Vinhos",
    categoria: "Bebidas",
    image: "/produtos/bebidas-generico.jpg",
  },
  {
    marca: "Cervejaria Salva",
    produto: "Salva Beer",
    categoria: "Bebidas",
    image: "/produtos/bebidas-generico.jpg",
  },
  {
    marca: "Gardana Jeans",
    produto: "Gardana Jeans",
    categoria: "Vestuário",
    image: "/produtos/gardana-jeans.jpg",
  },
  {
    marca: "Cooperativa Vinícola Aurora",
    produto: "Vinhos e Sucos",
    categoria: "Bebidas",
    image: "/produtos/bebidas-generico.jpg",
  },
  {
    marca: "Cooperativa Vinícola Garibaldi",
    produto: "Vinhos e Sucos",
    categoria: "Bebidas",
    image: "/produtos/bebidas-generico.jpg",
  },
  {
    marca: "Cerveja Polar",
    produto: "Cerveja Polar",
    categoria: "Bebidas",
    image: "/produtos/bebidas-generico.jpg",
  },
  {
    marca: "Dado Bier Cervejaria",
    produto: "Cervejas Dado Bier",
    categoria: "Bebidas",
    image: "/produtos/bebidas-generico.jpg",
  },
  {
    marca: "Vinícola Miolo",
    produto: "Vinhos",
    categoria: "Bebidas",
    image: "/produtos/bebidas-generico.jpg",
  },
  {
    marca: "Vinícola Perini",
    produto: "Vinhos e Sucos",
    categoria: "Bebidas",
    image: "/produtos/bebidas-generico.jpg",
  },
  {
    marca: "Vinícola Salton",
    produto: "Vinhos",
    categoria: "Bebidas",
    image: "/produtos/bebidas-generico.jpg",
  },
  {
    marca: "Alibem Alimentos",
    produto: "Carne Suína",
    categoria: "Carnes",
    image: "/produtos/carnes-generico.jpg",
  },
  {
    marca: "Dália Alimentos",
    produto: "Carne Suína e Frango",
    categoria: "Carnes",
    image: "/produtos/carnes-generico.jpg",
  },
  {
    marca: "Cooperativa Languiru",
    produto: "Frango",
    categoria: "Carnes",
    image: "/produtos/carnes-generico.jpg",
  },
  {
    marca: "Frigoríficos BRF (Unidades RS)",
    produto: "Carnes",
    categoria: "Carnes",
    image: "/produtos/carnes-generico.jpg",
  },
  {
    marca: "Bola",
    produto: "Linguiça do Bola",
    categoria: "Carnes",
    image: "/produtos/carnes-generico.jpg",
  },
  {
    marca: "Excelsior Alimentos",
    produto: "Carne Suína e Frango",
    categoria: "Carnes",
    image: "/produtos/carnes-generico.jpg",
  },
  {
    marca: "Frigorifico Silva",
    produto: "Carnes",
    categoria: "Carnes",
    image: "/produtos/carnes-generico.jpg",
  },
  {
    marca: "Frimesa",
    produto: "Carne Suína e Frango",
    categoria: "Carnes",
    image: "/produtos/carnes-generico.jpg",
  },
  {
    marca: "Frigoríficos JBS (Unidades RS)",
    produto: "Carnes",
    categoria: "Carnes",
    image: "/produtos/carnes-generico.jpg",
  },
  {
    marca: "Frigoríficos Marfrig (Unidades RS)",
    produto: "Carnes",
    categoria: "Carnes",
    image: "/produtos/carnes-generico.jpg",
  },
  {
    marca: "Lebon",
    produto: "Carnes",
    categoria: "Carnes",
    image: "/produtos/carnes-generico.jpg",
  },
  {
    marca: "Fontana",
    produto: "Sabonetes, Shampoos",
    categoria: "Higiene e Beleza",
    image: "/produtos/higiene-generico.jpg",
  },
  {
    marca: "Farmácia São João",
    produto: "Higiene e Beleza",
    categoria: "Higiene e Beleza",
    image: "/produtos/higiene-generico.jpg",
  },
  {
    marca: "Panvel",
    produto: "Higiene e Beleza",
    categoria: "Higiene e Beleza",
    image: "/produtos/higiene-generico.jpg",
  },
  {
    marca: "Gota Limpa",
    produto: "Produtos para Limpeza",
    categoria: "Limpeza",
    image: "/produtos/limpeza-generico.jpg",
  },
  {
    marca: "Bettanin",
    produto: "Produtos para Limpeza",
    categoria: "Limpeza",
    image: "/produtos/limpeza-generico.jpg",
  },
  {
    marca: "Fontana",
    produto: "Produtos para Limpeza",
    categoria: "Limpeza",
    image: "/produtos/limpeza-generico.jpg",
  },
  {
    marca: "Jimo",
    produto: "Jimo produtos domésticos",
    categoria: "Limpeza",
    image: "/produtos/limpeza-generico.jpg",
  },
  {
    marca: "Dália Alimentos",
    produto: "Laticínios",
    categoria: "Mercearia",
    image: "/produtos/mercearia-generico.jpg",
  },
  {
    marca: "Cooperativa Languiru",
    produto: "Laticínios",
    categoria: "Mercearia",
    image: "/produtos/mercearia-generico.jpg",
  },
  {
    marca: "Lider do Sul Alimentos",
    produto: "Arroz Italianinho",
    categoria: "Mercearia",
    image: "/produtos/mercearia-generico.jpg",
  },
  {
    marca: "Tio João",
    produto: "Arroz Tio João",
    categoria: "Mercearia",
    image: "/produtos/tio-joao.jpg",
  },
  {
    marca: "Conservas Oderich",
    produto: "Conservas",
    categoria: "Mercearia",
    image: "/produtos/oderich.jpg",
  },
  {
    marca: "Barão de Cotegipe Erva Mate",
    produto: "Erva Mate de Cotegipe",
    categoria: "Mercearia",
    image: "/produtos/erva-barao.jpg",
  },
  {
    marca: "Bistex",
    produto: "Salgadinhos",
    categoria: "Mercearia",
    image: "/produtos/bistex.jpg",
  },
  {
    marca: "Cooperativa Piá",
    produto: "Laticínios",
    categoria: "Mercearia",
    image: "/produtos/pia.jpg",
  },
  {
    marca: "Camil",
    produto: "Arroz Camil",
    categoria: "Mercearia",
    image: "/produtos/camil.jpg",
  },
  {
    marca: "DaColonia Alimentos",
    produto: "Da Colonia Rapaduras e Doces",
    categoria: "Mercearia",
    image: "/produtos/dacolonia.jpg",
  },
  {
    marca: "Totosinho",
    produto: "Totosinho",
    categoria: "Mercearia",
    image: "/produtos/totosinho.png",
  },
  {
    marca: "Docile Alimentos",
    produto: "Docile",
    categoria: "Mercearia",
    image: "/produtos/docile.png",
  },
  {
    marca: "Seiva Pura",
    produto: "Erva Mate Seiva Pura",
    categoria: "Mercearia",
    image: "/produtos/seivapura.jpg",
  },
  {
    marca: "Madrugada",
    produto: "Erva Mate Madrugada",
    categoria: "Mercearia",
    image: "/produtos/madrugada.jpg",
  },
  {
    marca: "Excelsior Alimentos",
    produto: "Laticínios",
    categoria: "Mercearia",
    image: "/produtos/excelsior.jpg",
  },
  {
    marca: "Florybal",
    produto: "Florybal Chocolates",
    categoria: "Mercearia",
    image: "/produtos/mercearia-generico.jpg",
  },
  {
    marca: "Frimesa",
    produto: "Laticínios",
    categoria: "Mercearia",
    image: "/produtos/mercearia-generico.jpg",
  },
  {
    marca: "Frohlich ",
    produto: "Fritz & Frida Arroz, Feijão e outros grãos",
    categoria: "Mercearia",
    image: "/produtos/mercearia-generico.jpg",
  },
  {
    marca: "Moinho Estrela",
    produto: "Panfácil farinhas",
    categoria: "Mercearia",
    image: "/produtos/mercearia-generico.jpg",
  },
  {
    marca: "Moinhos Cruzeiro do Sul",
    produto: "Farinha Rosa Branca ",
    categoria: "Mercearia",
    image: "/produtos/mercearia-generico.jpg",
  },
  {
    marca: "Pavioli",
    produto: "Salgadinhos Pavioli",
    categoria: "Mercearia",
    image: "/produtos/mercearia-generico.jpg",
  },
  {
    marca: "Pilecco Nobre Alimentos",
    produto: "Arroz Pillecco Nobre",
    categoria: "Mercearia",
    image: "/produtos/mercearia-generico.jpg",
  },
  {
    marca: "Prato Fino",
    produto: "Arroz Prato Fino",
    categoria: "Mercearia",
    image: "/produtos/mercearia-generico.jpg",
  },
  {
    marca: "Ritter Alimentos",
    produto: "Ritter Geléias",
    categoria: "Mercearia",
    image: "/produtos/mercearia-generico.jpg",
  },
  {
    marca: "Blue Ville",
    produto: "Blue Ville Arroz, Feijão e outros grãos",
    categoria: "Mercearia",
    image: "/produtos/mercearia-generico.jpg",
  },
  {
    marca: "Urbano",
    produto: "Urbano Arroz, Feijão e outros grãos",
    categoria: "Mercearia",
    image: "/produtos/mercearia-generico.jpg",
  },
  {
    marca: "Vigor",
    produto: "Laticínios",
    categoria: "Mercearia",
    image: "/produtos/mercearia-generico.jpg",
  },
  {
    marca: "Dália Alimentos",
    produto: "Leite",
    categoria: "Padaria e Matinais",
    image: "/produtos/padaria-generico.jpg",
  },
  {
    marca: "Farias Alimentos",
    produto: "Pães",
    categoria: "Padaria e Matinais",
    image: "/produtos/padaria-generico.jpg",
  },
  {
    marca: "Cooperativa Languiru",
    produto: "Leite",
    categoria: "Padaria e Matinais",
    image: "/produtos/padaria-generico.jpg",
  },
  {
    marca: "Bimbo",
    produto: "Pães e Bolos",
    categoria: "Padaria e Matinais",
    image: "/produtos/padaria-generico.jpg",
  },
  {
    marca: "Excelsior Alimentos",
    produto: "Leite",
    categoria: "Padaria e Matinais",
    image: "/produtos/padaria-generico.jpg",
  },
  {
    marca: "Frimesa",
    produto: "Leite",
    categoria: "Padaria e Matinais",
    image: "/produtos/padaria-generico.jpg",
  },
  {
    marca: "Wickbold",
    produto: "Pães Wickbold",
    categoria: "Padaria e Matinais",
    image: "/produtos/padaria-generico.jpg",
  },
  {
    marca: "Alisul ",
    produto: "Rações Bravo, Leroy, Nhock, Sapeca",
    categoria: "Pet",
    image: "/produtos/pet-generico.jpg",
  },
  {
    marca: "Tramontina",
    produto: "Móveis e Utensílios",
    categoria: "Utilidades Domésticas",
    image: "/produtos/utilidades-generico.jpg",
  },
  {
    marca: "Brinox",
    produto: "Utensílios Domésticos",
    categoria: "Utilidades Domésticas",
    image: "/produtos/utilidades-generico.jpg",
  },
  {
    marca: "Soprano",
    produto: "Utensílios Domésticos",
    categoria: "Utilidades Domésticas",
    image: "/produtos/utilidades-generico.jpg",
  },
  {
    marca: "Termolar",
    produto: "Utensílios Domésticos",
    categoria: "Utilidades Domésticas",
    image: "/produtos/utilidades-generico.jpg",
  },
  {
    marca: "Calçados Ferrarin",
    produto: "Calçados",
    categoria: "Vestuário",
    image: "/produtos/vestuario-generico.jpg",
  },
  {
    marca: "Calçados Beira Rio",
    produto: "Calçados",
    categoria: "Vestuário",
    image: "/produtos/vestuario-generico.jpg",
  },
  {
    marca: "Grendene",
    produto: "Calçados",
    categoria: "Vestuário",
    image: "/produtos/vestuario-generico.jpg",
  },
  {
    marca: "Calçados Via Uno",
    produto: "Calçados Via Uno",
    categoria: "Vestuário",
    image: "/produtos/vestuario-generico.jpg",
  },
  {
    marca: "Biamar",
    produto: "Malhas Biamar",
    categoria: "Vestuário",
    image: "/produtos/vestuario-generico.jpg",
  },
  {
    marca: "Anselmi",
    produto: "Malhas Anselmi",
    categoria: "Vestuário",
    image: "/produtos/vestuario-generico.jpg",
  },
];

// const Data = [
//   {
//     marca: "Fruki",
//     produto: "Guaraná Fruki 2 Litros",
//     image: "/produtos/fruki-2l.png",
//     categoria: "Bebidas",
//   },
//   {
//     marca: "Fruki",
//     produto: "Guaraná Fruki Zero 2 Litros",
//     image: "/produtos/fruki-zero.png",
//     categoria: "Bebidas",
//   },
//   {
//     marca: "Fruki",
//     produto: "Guaraná Fruki Garrafa vidro 600ml",
//     image: "/produtos/fruki-garrafa-vidro.png",
//     categoria: "Bebidas",
//   },
//   {
//     marca: "Fruki",
//     produto: "Cerveja Belavista long neck",
//     image: "/produtos/fruki-belavista-cerveja.png",
//     categoria: "Bebidas",
//   },
//   {
//     marca: "Aurora",
//     produto: "Suco de Uva Integral",
//     image:
//       "/produtos/AD6A73EE-B014-432E-9728-093371EB0DC6 - Rafaela Filter.jpeg",
//     categoria: "Bebidas",
//   },
//   {
//     marca: "Tio João",
//     produto: "Arroz Branco",
//     image:
//       "/produtos/70D1132C-7435-4173-8C4A-409C629BDB1D - Rafaela Filter.png",
//     categoria: "Mercearia",
//   },
//   {
//     marca: "Santa Clara",
//     produto: "Linguiça de Carne Suína",
//     image:
//       "/produtos/F2172886-3F26-4CCB-B4AB-664EC2A62615 - Rafaela Filter.png",
//     categoria: "Carnes",
//   },
//   {
//     marca: "Vinícola Salton",
//     produto: "Vinho Salton Intenso Tinto Suave",
//     image:
//       "/produtos/soufx37odexhfri4ywuzyxq1ncmnbhiizf0s_1600x2000+fill_ffffff.webp",
//     categoria: "Bebidas",
//   },
//   {
//     marca: "Calçados Beira Rio",
//     produto: "Bota preta 9076.206",
//     image: "/produtos/9076-206-20081-15745.jpg",
//     categoria: "Calçados",
//   },
//   {
//     marca: "Girando Sol",
//     produto: "Girando Sol Amaciante Tradicional Floral Paris 5L",
//     image: "/produtos/51QRyK9mXLL._AC_SL1200_.jpg",
//     categoria: "Limpeza",
//   },
//   {
//     marca: "Frigorífico Borrússia",
//     produto: "Lombo Resfriado de Suído",
//     image: "/produtos/lombo-resfriado-de-suino172406.jpg",
//     categoria: "Carnes",
//   },
//   {
//     marca: "Panvel Farmácias",
//     produto: "Creme Hidratante Óleo De Amêndoas E Aveia Panvel 500ml",
//     image: "/produtos/panvel-creme.webp",
//     categoria: "Higiene e Beleza",
//   },
//   {
//     marca: "Chocolates Neugebauer",
//     produto: "Chocolate Neugebauer Refeição 60g",
//     image: "/produtos/chocolate-refeicao.webp",
//     categoria: "Mercearia",
//   },
// ];

export default Data;
