// Product.js

export default function Product(props) {
  return (
    <div className="card">
      <div className="img-container">
        <img src={props.data.image} alt={props.data.produto} />
      </div>
      <div className="card-container">
        <h2>{props.data.produto}</h2>
        <h3>
          <label>Marca: </label>
          {props.data.marca}
        </h3>
        <h4>
          <label>Categoria: </label>
          {props.data.categoria}
        </h4>
      </div>
    </div>
  );
}
