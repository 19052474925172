import Select from "react-select";

const categorias = [
  { value: "Bebidas", label: "Bebidas" },
  { value: "Calçados", label: "Calçados" },
  { value: "Carnes", label: "Carnes" },
  { value: "Frios e Laticínios", label: "Frios e Laticínios" },
  { value: "Higiene e Beleza", label: "Higiene e Beleza" },
  { value: "Hortifruti", label: "Hortifruti" },
  { value: "Limpeza", label: "Limpeza" },
  { value: "Mercearia", label: "Mercearia" },
  { value: "Vestuário", label: "Vestuário" },
];

const marcas = [
  { value: "Aguardando Preenchimento", label: "Aguardando Preenchimento" },
];

export default function Filter() {
  return (
    <div id="filter">
      <div className="title">Filtros</div>
      <div className="filter-container">
        <Select
          placeholder="Selecione uma Categoria"
          options={categorias}
          isDisabled={true}
        />
        <Select
          placeholder="Selecione uma Marca"
          options={marcas}
          isDisabled={true}
        />
      </div>
    </div>
  );
}
