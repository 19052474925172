export default function Body() {
  return (
    <div id="body">
      <div className="body-container">
        <div className="texto-abertura">
          Comprando qualquer produto gaúcho você ajuda a reparar a catástrofe.
          Ajude a cadastrar aqui o produto que encontrar. É só tirar a foto e
          enviar. Um jeito simples e efetivo de ajudar. As marcas também podem
          cadastrar os seus principais produtos aqui. <br />
          <br />
          Siga{" "}
          <a href="https://www.instagram.com/consuma.rs/" target="_blank">
            @consuma.rs
          </a>{" "}
          no Instagram, poste uma foto comprando e marque o perfil para
          inspirar.
        </div>
        <div className="youtube">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/_kiaiGgj6ao?si=BiZIALr8kuwVg58f"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>

        <div className="selo">
          <a href="/img/selo-big-consuma-rs.png" download={true}>
            <img src="/img/button-selo.png" alt="baixe o selo" />
          </a>
        </div>

        <div className="colabore">
          <a
            href="https://forms.gle/JYBsa4HCyufAnmuG7"
            className="button"
            target="_blank"
            rel="noreferrer"
          >
            ❤️ Colabore enviando um produto que você conhece
          </a>
        </div>
      </div>
    </div>
  );
}
