export default function Rodape() {
  return (
    <div id="rodape">
      <div className="rodape-container">
        <div>Desenvolvido com ❤️ pelo Rio Grande por </div>
        <a href="https://www.instagram.com/rafaelafilter" target="_blank">
          <img src="/img/logo-cuhra.png" alt="CUHRA" height={"30"} />
        </a>
      </div>
    </div>
  );
}
