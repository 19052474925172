// App.js

import "./App.css";
import Product from "./components/Product";
import Welcome from "./components/Welcome";
import Body from "./components/Body";
import Rodape from "./components/Rodape";
import Filter from "./components/Filter";
import Data from "./components/Data";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyB6v9sW_f_x-jXRejGJsts7psK6caQ6sek",
  authDomain: "consumars-dcc0c.firebaseapp.com",
  projectId: "consumars-dcc0c",
  storageBucket: "consumars-dcc0c.appspot.com",
  messagingSenderId: "124531139894",
  appId: "1:124531139894:web:7267065731e0f86eb23f97",
  measurementId: "G-176NQTYGED",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  const data = Data;
  return (
    <div className="App">
      <Welcome />

      <div className="app-container">
        <Body />

        <Filter />

        <div id="produtos">
          <div className="produtos-container">
            {data
              .sort((a, b) => a.produto.localeCompare(b.produto))
              .map((e) => {
                return <Product data={e} />;
              })}
          </div>
        </div>

        <Rodape />
      </div>
    </div>
  );
}

export default App;

