// Welcome.js

export default function Welcome() {
  return (
    <div id="header">
      <div className="header-container">
        <div className="header-logo">
          <img src="img/logo-header.png" />
        </div>
        <div className="header-meio">
          <img src="img/logo-consumars.png" alt="consumars" />
          <h2>Ajude comprando um produto gaúcho</h2>
        </div>
        <div className="header-right">
          <div>
            <img src="img/logo-insta.png" alt="instagram" />
            <a href="https://www.instagram.com/consuma.rs/">@consuma.rs</a>
          </div>
        </div>
      </div>
    </div>
  );
}
